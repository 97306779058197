/* You can add global styles to this file, and also import other style files */
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";

img.logo {
  height: 20px;
  width: auto;
}

img {
  max-width: 100%;
  height: auto;
}

/* LOADING PAGE SHAPE */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.pace-inactive {
  display: none
}

.pace .pace-progress {
  background: #ec0000;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 10px
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ec0000, 0 0 5px #ee3148;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -moz-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  -o-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px)
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: #ec0000;
  border-left-color: #ec0000;
  border-radius: 10px;
  -webkit-animation: pace-spinner .4s linear infinite;
  -moz-animation: pace-spinner .4s linear infinite;
  -ms-animation: pace-spinner .4s linear infinite;
  -o-animation: pace-spinner .4s linear infinite;
  animation: pace-spinner .4s linear infinite
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

/* body.pace-running:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1500;
    background-color: rgba(40, 40, 40, 1);
  } */
.pace-running>*:not(.pace) {
  /* display: none; */
}

body:before {
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background-color 200ms;
  -moz-transtition: background-color 200ms;
  -ms-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  transition: background-color 200ms;
}

/* LOADING PAGE SHAPE */
